@font-face {
  font-family: "proxima-nova";
  src: url('./Proxima Nova Thin.woff') format('woff'),
    url('./Proxima Nova Thin.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url('./Proxima Nova Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url('./Proxima Nova Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url('./Proxima Nova Alt Bold.woff') format('woff'),
    url('./Proxima Nova Alt Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url('./Proxima Nova Alt Black.woff') format('woff'),
    url('./Proxima Nova Alt Black.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}