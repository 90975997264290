body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

html, body {
  position: relative;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex: 1 1;
}
@font-face {
  font-family: "proxima-nova";
  src: url("/static/media/Proxima Nova Thin.17aa4534.woff") format('woff'),
    url("/static/media/Proxima Nova Thin.0e628fcf.woff2") format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("/static/media/Proxima Nova Regular.410504d4.otf") format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("/static/media/Proxima Nova Semibold.4a2a396b.woff2") format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("/static/media/Proxima Nova Alt Bold.fa0710b8.woff") format('woff'),
    url("/static/media/Proxima Nova Alt Bold.d95e053c.woff2") format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("/static/media/Proxima Nova Alt Black.f9a7dd81.woff") format('woff'),
    url("/static/media/Proxima Nova Alt Black.759f3878.woff2") format('woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Whitney-Semibold';
  font-weight: bold;
  font-style: normal;
  src: url(/static/media/Whitney-Semibold.f0e7ffb4.woff) format('woff');
}

