body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

html, body {
  position: relative;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex: 1;
}